import { useToast } from 'vue-toastification'
import { Axios } from 'axios'

export default {
  namespaced: true,

  state: () => ({
    toast: useToast(),
    axios: new Axios({
      baseURL: 'https://web-api.reworlds.ru',
      transformRequest (data, headers) {
        headers['Content-Type'] = 'application/json'

        if (data) {
          return JSON.stringify(data)
        }
      },

      transformResponse (data) {
        try {
          return JSON.parse(data)
        } catch (error) {
          return { error }
        }
      },
      // headers: {
      //   'X-Useless': 'true'
      // },
      responseType: 'json'
    })
  }),

  getters: {
    getToast: state => state.toast,
    getAxios: state => state.axios
  }
}
