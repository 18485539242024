<template>
  <div style="margin: 0; padding: 0; min-height: 100vh">
    <header-component></header-component>
    <router-view v-slot="{ Component, route }">
      <transition name="router__fade" mode="out-in">
        <div class="its-component" :key="route.name">
          <component :is="Component"/>
        </div>
      </transition>
    </router-view>
  </div>

  <footer-component></footer-component>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --main-gradient: linear-gradient(90deg, #A18CD1 0%, #FBC2EB 100%);

  --text-hover-transition: 0.1s;

  --maximal-font-weight: 600;
  --medium-font-weight: 500;
  --minimal-font-weight: 400;

  --maximum-font-size: 30px;
  --pre-maximum-font-size: 26px;
  --maximal-font-size: 20px;
  --medium-font-size: 16px;
  --minimal-font-size: 14px;

  --maximal-rounding: 20px;
  --medium-rounding: 10px;
  --minimal-rounding: 8px;

  --maximal-font-color: #ffffff;
  --medium-font-color: #d2d2d2;
  --mediumal-font-color: #dcdcdc;
  --minimum-font-color: #ffffff;

  --pre-minimum-font-color: #9c9c9cff;
  --post-minimum-font-color: #a5a5a5ff;

  --post-maximal-background: #111111;
  --maximal-background: #191919;
  --medium-background: #363636;
  --minimal-background: #232323;

  --footer-text-color: rgba(130, 130, 130, 0.9);
  --footer-text-hover-color: rgba(180, 180, 180, 1.0);
}

[theme="light"] {
  --main-gradient: linear-gradient(90deg, #A18CD1 0%, #FBC2EB 100%);

  --text-hover-transition: 0.1s;

  --maximal-font-weight: 600;
  --medium-font-weight: 500;
  --minimal-font-weight: 400;

  --maximum-font-size: 30px;
  --maximal-font-size: 20px;
  --medium-font-size: 16px;
  --minimal-font-size: 14px;

  --maximal-rounding: 20px;
  --medium-rounding: 10px;
  --minimal-rounding: 8px;

  --maximal-font-color: #000000;
  --medium-font-color: #505050;
  --mediumal-font-color: #949494;
  --minimum-font-color: #ffffff;

  --post-maximal-background: #dedede;
  --maximal-background: #ffffff;
  --medium-background: #ececec;
  --minimal-background: #cbcbcb;

  --pre-minimum-font-color: #6a6a6a;
  --post-minimum-font-color: #505050;

  --footer-text-color: #505050;
  --footer-text-hover-color: #6c6c6c;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

.max-rounding {
  border-radius: var(--maximal-rounding);
}

.med-rounding {
  border-radius: var(--medium-rounding);
}

.min-rounding {
  border-radius: var(--minimal-rounding);
}

.post__max-background {
  background-color: var(--post-maximal-background);
}

.max-background {
  background-color: var(--maximal-background);
}

.med-background {
  background-color: var(--medium-background);
}

.max-color {
  color: var(--maximal-font-color);
}

.med-color {
  color: var(--medium-font-color);
}

.mim-color {
  color: var(--minimum-font-color);
}

.no-select {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

span {
  color: #a2a2a2;

  font-weight: 500;
}

p {
  font-size: 16px;
  font-weight: 400;
}

a {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

a[href] {
  color: #3ba0ff;

  transition: 0.1s;
}

a[href]:hover {
  color: #27669f;
}

.its-component {
  width: 100%;
  height: 100%;
}

@supports (overflow-x: clip) {
  body {
    overflow-x: clip;
  }
}

.router__fade-enter-active, .router__fade-leave-active {
  transition-property: opacity;
  transition-duration: .15s;
  overflow: hidden;
}

.router__fade-enter-active {
  transition-delay: .15s;
}

.router__fade-enter-from {
  max-height: 0;
  display: none;
}

.router__fade-enter-from, .router__fade-leave-active {
  opacity: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  font-family: 'Montserrat', Avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  color: var(--maximal-font-color);
  background-color: var(--maximal-background);
  transition: 0.3s;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--maximal-background);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--medium-font-color);
}
</style>

<script>
import FooterComponent from '@/components/Navigation/FooterComponent'
import HeaderComponent from '@/components/Navigation/HeaderComponent'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    ...mapGetters({
      isDebug: 'isDebug',
      getTheme: 'Display/getTheme'
    })
  },
  methods: {
    ...mapMutations({
      setMobile: 'Display/setMobile',
      setDisplay: 'Display/setDisplay'
    }),

    updateWidth: function () {
      this.setMobile(window.innerWidth < 768)

      this.setDisplay(window.innerWidth, window.innerHeight)
    }
  },
  mounted () {
    this.updateWidth()
    window.addEventListener('resize', this.updateWidth)
  },
  unmounted () {
    window.removeEventListener('resize', this.updateWidth)
  }
}
</script>
