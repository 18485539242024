export default function ({
  to,
  next
}) {
  if (sessionStorage.getItem('authorized') === 'true') {
    next({ path: '/' })
    return
  }

  next()
}
