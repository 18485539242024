<template>
  <div class="col-lg-6 d-flex align-items-center mt-5 mb-5">
    <slot name="icon"></slot>
    <div class="info-offset d-flex flex-column">
      <span class="title">{{ title }}</span>
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AboutComponent',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.icon-offset {
  width: 131px;
  height: 131px;
}

.info-offset {
  margin-left: 26px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 51px;
  letter-spacing: 0.01em;
  color: var(--maximal-font-color);
}

.content {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--medium-font-color);
}
</style>
