<template>
  <router-link v-if="!onlyPath || this.$route.path === to" style="padding: 8px 20px 8px 20px;"
               :class="(this.$route.path === to ? 'active ' : '') + 'nav-link'"
               :to="to">
    <font-awesome-icon :icon="icon" v-if="onlyPath ? getWidth <= 1280 : isMobile"></font-awesome-icon>
    <slot v-else></slot>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LinkComponent',
  props: {
    to: String,
    icon: [Object, Array, String],
    onlyPath: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'Display/isMobile',
      getWidth: 'Display/getWidth'
    })
  }
}
</script>

<style scoped>
</style>
