import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toast, { POSITION, TYPE } from 'vue-toastification'
import '@/assets/styles/toastification.css'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faHome,
  faBook,
  faPenNib,
  faHandHoldingUsd,
  faUserAlt,
  faUserSecret,
  faHouseUser,
  faSun,
  faMoon,
  faEnvelope,
  faMap,
  faXmark,
  faCube,
  faCode,
  faCopy
} from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faVk, faTelegram, faSketch, faYoutube } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHome, faBook, faPenNib, faHandHoldingUsd, faUserAlt, faUserSecret, faHouseUser, faSun, faMoon, faEnvelope, faMap, faXmark, faDiscord, faVk, faTelegram, faSketch, faYoutube, faCube, faCode, faCopy)

const options = {
  position: POSITION.TOP_RIGHT,
  maxToasts: 5,

  toastDefaults: {
    [TYPE.ERROR]: {
      timeout: 2500,
      pauseOnHover: false
    },
    [TYPE.INFO]: {
      timeout: 3000,
      pauseOnHover: false
    },
    [TYPE.SUCCESS]: {
      timeout: 3000,
      pauseOnHover: false
    }
  }
}

const theme = localStorage.getItem('theme')
if (theme && (theme === 'light' || theme === 'dark')) {
  document.documentElement.setAttribute('theme', theme)
}

const app = createApp(App)
app
  .use(VueAxios, axios)
  .provide('axios', app.config.globalProperties.axios)

app.config.globalProperties.$store = store
app.component('font-awesome-icon', FontAwesomeIcon)
  .use(Toast, options)
  .use(store)
  .use(router)
  .mount('#app')
