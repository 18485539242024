<template>
  <div class="title">
    <div class="container">
      <div class="row fake-title align-items-center justify-content-between">
        <div class="col d-flex flex-column">
          <span class="static-title"
                :style="isMobile ? 'padding-top: 10px; font-size: 32px; line-height: 32px; letter-spacing: 0.005em' : ''">Классическое выживание<br>с <span
            class="main-gradient">RolePlay</span> элементами</span>
          <span class="title-mini" :style="isMobile ? 'font-size: 16px; letter-spacing: 0.001em' : ''">Выживайте, стройте города, отыгрывайте RP и находите друзей.<br>Без приватов, гриферства и агрессии.</span>
        </div>

        <div class="col-lg-2 d-flex flex-column" :style="isMobile ? 'padding: 10px' : ''">
          <div class="no-select monitoring text-center mim-color min-rounding" @click="openDiscord">
            Присоединиться
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-advantage">
    <div class="container">
      <div :class="'row d-flex' + (isMobile ? 'text-center' : '')">
        <about-component title="Популярность">
          <template v-slot:icon>
            <img v-if="!isMobile" class="icon-offset" src="../assets/images/icons/mdi_infinity.svg" alt="">
          </template>
          <template v-slot:content>
            <span>Discord проекта является одним из крупных<br>среди бесплатных приватных серверов, <br>в нём состоит более 9.500 участников.</span>
          </template>
          x
        </about-component>

        <about-component title="Играбельность">
          <template v-slot:icon>
            <img v-if="!isMobile" class="icon-offset me-1 ms-1" src="../assets/images/icons/server-solid.svg" alt="">
          </template>
          <template v-slot:content>
            <span>Вход через прокси в России или в Германии,<br>что обеспечивает минимальный пинг для<br>множества стран СНГ и не только.</span>
          </template>
        </about-component>

        <about-component title="Публичность">
          <template v-slot:icon>
            <img v-if="!isMobile" class="icon-offset" src="../assets/images/icons/mdi_earth.svg" alt="">
          </template>
          <template v-slot:content>
            <span>Сервер полностью бесплатный.<br>Поддерживается подключение с Java/Bedrock<br>без лицензии Minecraft.</span>
          </template>
        </about-component>

        <about-component title="Сплоченность">
          <template v-slot:icon>
            <img v-if="!isMobile" class="icon-offset" src="../assets/images/icons/mdi_account-group.svg" alt="">
          </template>
          <template v-slot:content>
            <span>Города сервера собирают сотни участников и<br>создают гигантские постройки. Организации<br>работают на всеобщее благо.</span>
          </template>
        </about-component>

        <about-component title="Классика">
          <template v-slot:icon>
            <img v-if="!isMobile" class="icon-offset" src="../assets/images/icons/mdi_check.svg" alt="">
          </template>
          <template v-slot:content>
            <span>Отсутствие приватов и лимитов построек.<br>Доход с некоторых ферм уменьшен<br>для поддержания экономики цен.</span>
          </template>
        </about-component>

        <about-component title="Ивенты">
          <template v-slot:icon>
            <img v-if="!isMobile" class="icon-offset" src="../assets/images/icons/mdi_calendar.svg" alt="">
          </template>
          <template v-slot:content>
            <span>Администрация проводит обходы и выборы.<br>Игроки и выбранный президент проводят<br>аукционы, гонки, суды, концерты и т.д.</span>
          </template>
        </about-component>
      </div>
    </div>
  </div>
</template>

<script>
import AboutComponent from '@/components/Home/AboutComponent'
import { mapGetters } from 'vuex'

export default {
  name: 'HomePage',
  components: { AboutComponent },
  computed: {
    ...mapGetters({
      isMobile: 'Display/isMobile'
    })
  },
  methods: {
    openDiscord: function () {
      window.open('https://discord.gg/3sABA2kEDw')
    }
  },
  mounted () {
    window.top.document.title = 'RevolutionWorlds - Главная'
  }
}
</script>

<style scoped>
.main-gradient {
  background-image: linear-gradient(90deg, #A18CD1 0%, #FBC2EB 100%) !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
}

.fake-title {
  min-height: 430px;
}

.title {
  background: linear-gradient(0deg, rgba(25, 25, 25, 0.9), rgba(25, 25, 25, 0.9)), url('../assets/images/_3-J2KgdRAE.jpg') no-repeat center;
}

[theme="light"] .title {
  background: linear-gradient(0deg, rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.8)), url('../assets/images/_3-J2KgdRAE.jpg') no-repeat center;
}

.title {
  background-size: cover;
  height: 430px;
  display: flex;
  /*width: 100%;*/
  /*padding: 136px 0;*/
}

.static-title {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 45px;
  letter-spacing: 0.01em;
  color: var(--minimum-font-color);
  margin-bottom: 24px;
}

.title-mini {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: var(--mediumal-font-color);
}

.default-offset {
  margin: 18px;
}

.section-advantage {
  margin-top: 100px;
  margin-bottom: 100px;
}

.monitoring {
  padding: 12px 0 12px 0;

  font-weight: 500;

  font-size: 16px;

  background: rgba(131, 126, 126, 0.2);
  box-shadow: 0 2px 32px 0 rgb(20, 20, 20);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 8px;
  cursor: pointer;

  transition: 0.2s;
}

[theme="light"] .monitoring {
  background: rgba(211, 202, 202, 0.2);
  box-shadow: 0 2px 32px 0 rgb(66, 66, 66);
}

.monitoring:hover {
  background: rgba(176, 170, 170, 0.2);
  box-shadow: 0 2px 32px 0 rgb(50, 50, 50);
}

[theme="light"] .monitoring:hover {
  background: rgba(176, 170, 170, 0.2);
  box-shadow: 0 2px 32px 0 rgb(88, 88, 88);
}
</style>
