import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage'

import AuthMiddleware from './middleware/auth'
import GuestMiddleware from './middleware/guest'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/pages/PrivacyPage')
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('@/pages/InfoPage')
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('@/pages/RulesPage')
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: (to, from, next) => GuestMiddleware({
      to,
      next
    }),
    component: () => import('@/pages/LoginPage')
  },
  {
    path: '/cabinet',
    name: 'Cabinet',
    beforeEnter: (to, from, next) => AuthMiddleware({
      to,
      next
    }),
    component: () => import('@/pages/CabinetPage')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/pages/TermsPage')
  },
  {
    path: '/catalog',
    name: 'Donate',
    component: () => import('@/pages/DonatePage')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('@/pages/MapPage')
  },
  {
    path: '/safety',
    name: 'Safety',
    component: () => import('@/pages/SafetyPage')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
