import { createStore } from 'vuex'
import Auth from '@/store/modules/auth'
import Display from '@/store/modules/display'
import Toast from '@/store/modules/toast'
import User from '@/store/modules/user'

export default createStore({
  state: {
    debug: false
  },

  getters: {
    isDebug: state => state.debug
  },

  mutations: {
    setDebug (state, debug) {
      state.debug = debug
    }
  },

  actions: {},
  modules: {
    Display,
    Toast,
    Auth,
    User
  }
})
