export default {
  namespaced: true,

  state: () => ({
    width: 1280,
    height: 960,
    mobile: false,
    theme: localStorage.getItem('theme')
  }),

  getters: {
    getTheme: state => state.theme,
    getWidth: state => state.width,
    getHeight: state => state.height,
    isMobile: state => state.mobile
  },

  mutations: {
    toggleTheme (state) {
      if (!state.theme || state.theme === 'dark') {
        state.theme = 'light'
      } else {
        state.theme = 'dark'
      }

      localStorage.setItem('theme', state.theme)
      document.documentElement.setAttribute('theme', state.theme)
    },

    setDisplay (state, width, height) {
      state.width = width
      state.height = height
    },

    setMobile (state, mobile) {
      state.mobile = mobile
    }
  }

  // actions: {
  //   login () { ...
  //   } // -> dispatch('account/login')
  // },
  // mutations: {
  //   login () { ...
  //   } // -> commit('account/login')
  // },
}
