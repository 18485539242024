<template>
  <div class="container">
    <div class="header d-flex justify-content-center">
      <div class="logo">
      </div>
      <div class="nav">
        <link-component :icon="['fas', 'home']" to="/" class="nav-element">Главная</link-component>
        <link-component :icon="['fas', 'book']" to="/rules" class="nav-element">Правила</link-component>
        <link-component :icon="['fas', 'pen-nib']" to="/info" class="nav-element">Справка</link-component>
        <link-component :icon="['fas', 'map']" to="/map" class="nav-element">Карта</link-component>
        <link-component :icon="['fas', 'hand-holding-usd']" to="/catalog" class="nav-element">Привилегии</link-component>
        <link-component :onlyPath="true" :icon="['fas', 'user-secret']" to="/privacy" class="nav-element">Конфиденциальность</link-component>
        <link-component :onlyPath="true" :icon="['fas', 'user-secret']" to="/terms" class="nav-element">Соглашение</link-component>
        <link-component :onlyPath="true" :icon="['fas', 'user-secret']" to="/safety" class="nav-element">Безопасность</link-component>
        <link-component :onlyPath="true" :icon="['fas', 'user-alt']" to="/login" class="nav-element">Авторизация</link-component>
        <link-component :onlyPath="true" :icon="['fas', 'house-user']" to="/cabinet" class="nav-element">Личный кабинет</link-component>
      </div>
    </div>
  </div>
</template>

<script>
import LinkComponent from '@/components/Navigation/LinkComponent'
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderComponent',
  components: { LinkComponent },
  computed: {
    ...mapGetters({
      isMobile: 'Display/isMobile'
    })
  }
}
</script>

<script>

</script>

<style scoped>
.header {
  margin: 66px 0px 66px;
}

.nav-element {
  padding: 8px 13px !important;
}

.nav-link {
  padding: 8px 8px;
  text-decoration: none !important;
  color: var(--maximal-font-color);
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
  letter-spacing: 0.01em;
}

/*.active {*/
/*  border-radius: 12px;*/
/*  background-color: var(--medium-background);*/
/*}*/

.active {
  /*padding-left: 10px;*/
  /*padding-right: 10px;*/
  border-radius: 8px;
  background-color: var(--medium-background);
  /*border-bottom: 3px solid #1C6EA4;*/
}

.nav-item .nav-link {
  font-size: 14px;
  font-weight: 500;
  color: var(--maximal-font-color);
  border-radius: 8px;
}

.nav-link {
  font-size: 14px;
  color: var(--maximal-font-color);
  font-weight: 500;
}

.nav-link:hover {
  font-size: 14px;
  color: var(--medium-font-color);
}

.nav-link:first-child {
  margin-left: 0;
}
</style>
