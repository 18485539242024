export default {
  namespaced: true,

  state: () => ({
    secretToken: '',
    token: ''
  }),

  getters: {
    getToken: state => state.token,
    getSecretToken: state => state.token,
    hasToken: state => state.token !== null && state.token.length > 0,
    hasSecretToken: state => state.secretToken !== null && state.secretToken.length > 0
  },

  actions: {
    login: (state, {
      username,
      password
    }) =>
      new Promise((resolve, reject) =>
        state.rootGetters['Toast/getAxios'].post('/login', {
          username: username,
          password: password
        })
          .then(response => {
            state.commit('setToken', response.data.token)
            state.commit('setToken', response.data.token)
            state.commit('User/setUser', response.data.user)
            resolve(response)
          })
          .catch(error => reject(error))
      )
  },

  mutations: {
    setToken (state, token) {
      state.token = token
    },

    setSecretToken (state, token) {
      state.secretToken = token
    }
  }
}
