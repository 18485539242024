<template>
  <footer class="text-center text-lg-start text-muted post__max-background">
    <div class="text-center p-3 post__max-background">
      <p class="m-0 no-select">
        <span class="m-2 footer-text no-select" @click="click">© RevolutionWorlds 2019-2023</span>
        <br v-if="isMobile"><span class="footer-text pe-1" v-else>| </span>
        <span v-if="isMobile" class="footer-text">Переключение темы: </span>
        <font-awesome-icon class="ms-1 pe-2 footer-text" @click="toggleTheme"
                           :icon="['fas', this.getTheme === 'light' ? 'moon' : 'sun']"></font-awesome-icon>
        <br v-if="isMobile"><span class="footer-text" v-else> |</span>
        <span class="m-2 footer-text no-select" @click="click">ИП Кулбужев М. Х.-Б.</span>
        <br v-if="isMobile"><span class="footer-text" v-else>|</span>
        <span class="m-2 footer-text no-select" @click="click">ИНН 150205452161</span>
        <br v-if="isMobile"><span class="footer-text" v-else>|</span>
        <span class="m-2 footer-text no-select" @click="click">ОГРН 323060000008081</span>
        <!--        |-->
        <!--        <router-link class="m-2" to="/terms">Пользовательское соглашение</router-link>-->
        <br>
        <span v-if="isDebug" class="m-2 footer-text no-select">DEBUG MODE</span>
        <router-link class="m-2 footer-text no-select" to="/terms">Пользовательское соглашение</router-link>
        <br v-if="isMobile"><span class="footer-text" v-else>|</span>
        <router-link class="m-2 footer-text no-select" to="/privacy">Политика конфиденциальности</router-link>
        <br v-if="isMobile"><span class="footer-text" v-else>|</span>
        <router-link class="m-2 footer-text no-select" to="/safety">Политика безопасности</router-link>
        <br>
        <!--<span v-if="isMobile" class="m-2 footer-text no-select">Мы принимаем:</span>-->
        <img :class="(isMobile ? '' : 'mx-2 ') + 'footer-text no-select'" height="20"
             src="../../assets/images/payment-system-logos2.png" alt="" style="margin-top: 12px !important; padding-top: 3px !important;"/>
        <img :class="(isMobile ? '' : 'mx-2 ') + 'footer-text no-select'" height="40"
             src="../../assets/images/icons/tinkoff.svg" alt="" style="margin-top: 12px !important; padding-top: 3px !important;"/>
      </p>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FooterComponent',
  data: () => ({
    clicks: []
  }),

  computed: {
    ...mapGetters({
      isDebug: 'isDebug',
      isMobile: 'Display/isMobile',
      getTheme: 'Display/getTheme'
    })
  },

  methods: {
    ...mapMutations({
      toggleTheme: 'Display/toggleTheme'
    }),

    click: function () {
      if (this.isDebug) {
        if (this.clicks.length !== 0) {
          this.clicks = []
        }
        return
      }

      const currentTime = new Date().getTime()
      this.clicks.push(currentTime)
      if (this.clicks.length >= 5) {
        if (this.clicks.length > 5) {
          this.clicks.shift()
        }

        let count = 0
        for (const i in this.clicks) {
          if (currentTime - this.clicks[i] <= 5000) {
            count++
          }
        }

        if (count >= 5) {
          this.$store.commit('setDebug', true)
        }
      }
    }
  }
}
</script>

<style scoped>
footer {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -80px;
}

p {
  color: var(--footer-text-color);

  text-decoration: none;

  font-weight: 500;
  font-size: 16px;
}

.footer-text {
  color: var(--footer-text-color);

  text-decoration: none;

  font-weight: var(--minimal-font-weight);
  font-size: var(--minimal-font-size);

  transition: var(--text-hover-transition);
}

.footer-text:hover {
  color: var(--footer-text-hover-color);
}
</style>
